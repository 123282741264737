
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

type ResetStep = "forgot" | "verify" | "reset" | "complete" | "error";

@Component({
	components: {
		ForgotPinForm: () => LazyLoad(import(/* webpackChunkName: "forgot-pin.form" */ "./../components/forms/ForgotPinForm.vue")),

		VerifyForm: () => LazyLoad(import(/* webpackChunkName: "verify.form" */ "./../components/forms/VerifyForm.vue")),
		ResetPinForm: () => LazyLoad(import(/* webpackChunkName: "reset-pin.form" */ "./../components/forms/ResetPinForm.vue")),
		SuccessRedirect: () => LazyLoad(import(/* webpackChunkName: "success-redirect" */ "./../components/SuccessRedirect.vue")),
	},
})
export default class Forgot extends Vue {
	@Prop({ default: {} })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public defaultCountry!: string;

	@Prop({ required: true })
	public countries!: string[];

	public actions = {
		forgot: process.env.VUE_APP_FORGOT_PIN_ACTION,
		reset: process.env.VUE_APP_RESET_PIN_ACTION,
		verify: process.env.VUE_APP_VERIFY_ACTION,
	};

	@Ref("alert")
	public readonly $alert!: AlertComponentInterface;

	@Ref("success")
	public readonly $success!: InstanceType<any>;

	@Ref("verifyForm")
	public readonly $verifyForm!: InstanceType<any>;

	public step: ResetStep = "forgot";

	public result: Record<"begin" | "verification" | "complete", any> = {
		begin: {},
		verification: {},
		complete: {},
	};

	created() {
		console.log("Return url", this.returnUrl);
		console.log("Return url is internal? ", this.$isInternalUrl(this.returnUrl));
	}

	get signinUrl() {
		return this.$url(process.env.VUE_APP_SIGNIN_URL as string, this.query);
	}

	get returnUrl() {
		return this.query.continue ?? "";
	}

	get verifyTitle() {
		switch (this.result.begin.mode) {
			case "phone":
				return "Verify Your Phone Number";

			case "email":
				return "Verify Your Email";

			default:
				return "Verify Account";
		}
	}

	get metadata() {
		return {
			ref: this.result.begin.ref,
			principal: this.result.begin.principal,
			token: this.result.verification.token,
		};
	}

	onForgotDone({ data }: any) {
		this.result.begin = data;
		this.step = "verify";
	}

	onVerificationDone({ data }: any) {
		this.result.verification = data;
		this.step = "reset";
	}

	onResetDone() {
		this.step = "complete";
		this.$success.count();
	}
}
